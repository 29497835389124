import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import { env } from './environment';

const bugsnagClient = bugsnag({
  apiKey: env.bugsnagId,
  releaseStage: env.platform,
  beforeSend: report => {
    if (!env.isDeployed) {
      report.ignore();
    }
  },
});

bugsnagClient.use(bugsnagReact, React);

export const ErrorBoundary = bugsnagClient.getPlugin('react');
