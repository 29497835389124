const platform = process.env.PLATFORM || 'local';
const apiPlatform = process.env.API_PLATFORM || 'staging';
const wizbiiFiles = process.env.FILE_API_BUCKET || 'wizbii-files-qa3';

const apiDomain = process.env.API_DOMAIN || 'staging.k8s.wizbii.us';

export const env = {
  applicationId: 'jobs',
  platform,
  isDeployed: !!process.env.PLATFORM,
  apiPlatform,
  apiDomain,
  siteUrl:
    platform === 'prod'
      ? 'https://event.wizbii.com'
      : platform !== 'local'
      ? `https://event.${platform}.wizbii.us`
      : '',
  api: {
    consent: `https://consent.${apiDomain}`,
    wizbiiAnalytiks: `https://a.${apiDomain}`,
    opsEvent: `https://ops-event.api.${apiDomain}`,
  },
  wizbiiFiles,
  googleStorage: 'https://storage.googleapis.com',
  bugsnagId: '61b578eeab548bb6f5e748e8ee694b1c',
  waTrackingId: process.env.WA_TRACKING_ID,
  gaTrackingId: process.env.GA_TRACKING_ID,
  gdprWidgetUrl: `https://storage.googleapis.com/wizbii/consent/${apiPlatform}/widget/bundle.js`,
};
