import { env } from './environment';
import { createGoogleAnalyticsConfig } from '@wizbii/tracking';

const trackingConfig = {
  waApiUrl: env.api.wizbiiAnalytiks,
  waTrackingId: env.waTrackingId,

  consentWidget: {
    url: env.gdprWidgetUrl,
    apiDomain: env.apiDomain,
    locale: 'fr',
    productId: env.applicationId,
    productLabel: 'Wizbii | Event',
  },

  trackers: {
    analytics_google: createGoogleAnalyticsConfig(env.gaTrackingId),
  },
};

export default trackingConfig;
