import { OpsEventContext } from './ops-event.context';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function OpsEventProvider({ children }) {
  const [opsEventContext, setOpsEventContext] = useState({
    opsEvent: undefined,
    eventType: undefined,
    consents: undefined,
    initialConsents: undefined,
    setState: (event, eventType, consents) => {
      setOpsEventContext({
        ...opsEventContext,
        opsEvent: event,
        eventType,
        consents,
        initialConsents: consents,
      });
    },
    updateConsents: (event, eventType, initialConsents, consents) => {
      setOpsEventContext({
        ...opsEventContext,
        opsEvent: event,
        eventType,
        consents,
        initialConsents,
      });
    },
  });

  return (
    <OpsEventContext.Provider value={opsEventContext}>
      {children}
    </OpsEventContext.Provider>
  );
}

OpsEventProvider.propTypes = {
  children: PropTypes.node,
};
