import './src/styles/global.css';

import React from 'react';
import PropTypes from 'prop-types';
import { setupTracking, track } from '@wizbii/tracking';

import { ErrorBoundary } from './src/bugsnag';
import config from './src/tracking-config';
import { OpsEventProvider } from './src/OpsEventProvider';

export function onClientEntry() {
  // Set up tracking on initial SPA load
  setupTracking(config);
}

export function onRouteUpdate({ location }) {
  const { href: url, pathname, search, hash } = location;
  const page = pathname + search + hash;

  // Wrap inside two rAFs to make sure react-helmet is done with its changes
  // - https://github.com/gatsbyjs/gatsby/issues/9139
  // - https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      track('pageview', { url, page });
    });
  });
}

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <OpsEventProvider>{element}</OpsEventProvider>
  </ErrorBoundary>
);

wrapRootElement.propTypes = {
  element: PropTypes.object,
};
